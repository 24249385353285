import Papa from 'papaparse'
import { flatten, isEmpty, union } from 'lodash'
//import * as XLSX from 'xlsx'

import { dispatchClassifications } from '../../redux/classification/actions'
import { dispatchError } from '../../redux/notifications/actions'
import { isInvalidClassification, isInvalidRequest } from './validator'
import store from '../../redux/index'

//const DEFAULT_SHEET_NAME = 'classifications'

export function handleUpload(file) {
  let fileType = file.name.split('.').pop()
  const reader = new FileReader()
  if (fileType === 'csv') {
    reader.onload = handleCsv
    reader.readAsText(file)
  } else {
    throw new Error('Unsupported file type.')
  }
}

/* Not Currently supporting, but left here in case we do decide to allow it.

  should be read as binary string
    - reader.readAsBinaryString(file)

 function handleXlsx(event) {
   const content = event.target.result
   let book = XLSX.read(content, { type: 'binary' })
   const sheetName =
     book.SheetNames.find((name) => {
       return name === DEFAULT_SHEET_NAME
     }) || book.SheetNames[0]
   const sheet = book.Sheets[sheetName]
   const data = XLSX.utils.sheet_to_csv(sheet, { header: 1 })
   let classifications = Papa.parse(data, { header: true, skipEmptyLines: true })
     .data
   let data = transformData(classifications)
   if (data.length === 0) {
     dispatchError(
       'File did not contain valid data. Please upload a csv with proper customs data. See the template for what data you need.'
     )
   } else {
     dispatchClassifications(data)
   }
} */

function handleCsv(event) {
  const content = event.target.result
  let data = transformData(content)
  if (data.length === 0) {
    dispatchError(
      'File did not contain valid data. Please upload a csv with proper customs data. See the template for what data you need.'
    )
  } else {
    dispatchClassifications(data)
  }
}

export function transformData(content) {
  const {
    submissionType
  } = store.getState().classification.selectedImporterOfRecord
  if (submissionType === 'request') {
    let classifications = Papa.parse(content, {
      header: false,
      skipEmptyLines: true
    }).data
    // remove header
    classifications.shift()
    const transformedClassification = classifications.map((classification) => {
      let mcpSku,
        countryOfOrigin,
        accountId,
        productDescription,
        material,
        material2,
        material3,
        color,
        size,
        gender,
        apparelBuild,
        manufacturerProductLink,
        averageSellingPrice
      let attributes = {}
      for (let i = 0; i < classification.length; i++) {
        const field = classification[i]
        if (!field) continue
        switch (i) {
          case 0:
            mcpSku = getField(field)
            break
          case 1:
            accountId = getField(field)
            break
          case 2:
            countryOfOrigin = getField(field)
            break
          case 3:
            productDescription = getField(field)
            break
          case 4:
            material = getField(field)
            break
          case 5:
            material2 = getField(field)
            break
          case 6:
            material3 = getField(field)
            break
          case 7:
            color = getField(field)
            break
          case 8:
            size = getField(field)
            break
          case 9:
            gender = getField(field)
            break
          case 10:
            apparelBuild = getField(field)
            break
          case 11:
            manufacturerProductLink = getField(field)
            break
          case 12:
            averageSellingPrice = getField(field)
            break
          default:
            let attributePairs = getField(field)
            if (!attributePairs) break
            else attributePairs = attributePairs.split(/:(.*)/, 2)
            if (!(attributePairs.length === 2 && attributePairs[1])) {
              dispatchError(
                `Attribute format in ${field} does not match schema.`
              )
            } else {
              const key = attributePairs[0].trim()
              const value = attributePairs[1].trim()
              attributes[key] = value
            }
            break
        }
      }

      let mapping = {
        mcpSku,
        accountId,
        countryOfOrigin,
        productDescription,
        material,
        material2,
        material3,
        color,
        size,
        gender,
        apparelBuild,
        manufacturerProductLink,
        averageSellingPrice
      }

      if (!isEmpty(attributes)) mapping.attributes = attributes
      return mapping
    })
    return isInvalidRequest(transformedClassification)
  } else if (submissionType === 'classification') {
    let classifications = Papa.parse(content, {
      header: false,
      skipEmptyLines: true,
      transform: (value) => {
        return value.trim()
      }
    }).data
    classifications.shift()
    let transformedClassification = classifications.map((classification) => {
      let mcpSku,
        countryOfOrigin,
        countriesOfClassification,
        htsCode,
        preferentialOriginCountries,
        notPreferentialOriginCountries
      let attributes = {}
      for (let i = 0; i < classification.length; i++) {
        const field = classification[i]
        if (!field) continue
        switch (i) {
          case 0:
            mcpSku = getField(field)
            break
          case 1:
            countryOfOrigin = getField(field)
            break
          case 2:
            countriesOfClassification = getField(field)
              .split(';')
              .map((str) => str.trim())
              .filter((str) => !!str)
            break
          case 3:
            htsCode = getField(field)
            break
          case 4:
            preferentialOriginCountries = getField(field)
              .split(';')
              .map((str) => str.trim())
              .filter((str) => !!str)
            break
          case 5:
            notPreferentialOriginCountries = getField(field)
              .split(';')
              .map((str) => str.trim())
              .filter((str) => !!str)
            break
          default:
            let attributePairs = getField(field)
            if (!attributePairs) break
            else attributePairs = attributePairs.split(/:(.*)/, 2)
            if (!(attributePairs.length === 2 && attributePairs[1])) {
              dispatchError(
                `Attribute format in ${field} does not match schema.`
              )
            } else {
              const key = attributePairs[0].trim()
              const value = attributePairs[1].trim()
              attributes[key] = value
            }
            break
        }
      }

      if (
        countriesOfClassification ||
        preferentialOriginCountries ||
        notPreferentialOriginCountries
      ) {
        const countries = union(
          preferentialOriginCountries,
          notPreferentialOriginCountries,
          countriesOfClassification
        )
        return countries.map((countryOfClassification) => {
          let preferentialOrigin = isPreferentialOrigin(
            countryOfClassification,
            preferentialOriginCountries,
            notPreferentialOriginCountries
          )
          let mapping = {
            mcpSku,
            countryOfOrigin,
            countryOfClassification,
            htsCode,
            preferentialOrigin
          }
          if (!isEmpty(attributes)) mapping.attributes = attributes
          return mapping
        })
      } else {
        let mapping = {
          mcpSku,
          countryOfOrigin
        }
        if (!isEmpty(attributes)) mapping.attributes = attributes
        return mapping
      }
    })
    return isInvalidClassification(flatten(transformedClassification))
  }
}

function isPreferentialOrigin(
  countryOfClassification,
  preferentialOriginCountries,
  notPreferentialOriginCountries
) {
  const preferentialOrigin =
    preferentialOriginCountries &&
    preferentialOriginCountries.length &&
    preferentialOriginCountries.includes(countryOfClassification)
  const notPreferentialOrigin =
    notPreferentialOriginCountries &&
    notPreferentialOriginCountries.length &&
    notPreferentialOriginCountries.includes(countryOfClassification)

  if (preferentialOrigin && notPreferentialOrigin) {
    dispatchError(
      `countryOfClassification ${countryOfClassification} is in both preferential-origin country list and not-preferential-origin country list, please confirm which list it should belong to then upload the spreadsheet again.`
    )
    return
  } else if (preferentialOrigin) return 'true'
  else if (notPreferentialOrigin) return 'false'
  else return
}

function getField(field) {
  return field && field.trim()
}
