import React from 'react'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import { Button, ReactTablev6 } from '@cimpress/react-components'
import { cloneDeep, isEmpty, keys } from 'lodash'
import { dispatchError, dispatchMessage } from '../redux/notifications/actions'
import * as classeService from '../lib/classe/classeService'
import { dispatchWait } from '../redux/app/actions'
import {
  dispatchClassifications,
  dispatchFile
} from '../redux/classification/actions'

export class ClassificationDisplay extends React.Component {
  constructor(props) {
    super(props)
    autobind(this)

    let formattedData
    let materialColumns = [],
      attributeColumns = []

    formattedData = this.formatData(cloneDeep(this.props.classifications))
    materialColumns = formattedData.materialColumns
    attributeColumns = formattedData.attributeColumns

    const data = {
      request: formattedData.data,
      classification: formattedData.data
    }

    const columns = {
      request: [
        { Header: 'Fulfillment SKU', accessor: 'mcpSku' },
        { Header: 'Account ID', accessor: 'accountId' },
        { Header: 'Country of Origin', accessor: 'countryOfOrigin' },
        { Header: 'Product Description', accessor: 'productDescription' },
        ...materialColumns,
        {
          Header: 'Manufacturer Product Link',
          accessor: 'manufacturerProductLink',
          style: { textOverflow: 'ellipsis' },
          Cell: ({ row }) => {
            return (
              <a
                href={row.manufacturerProductLink}
                style={{
                  display: 'block',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
              >
                {row.manufacturerProductLink}
              </a>
            )
          }
        },
        { Header: 'Color', accessor: 'color' },
        { Header: 'Size', accessor: 'size' },
        { Header: 'Gender', accessor: 'gender' },
        { Header: 'Apparel Build', accessor: 'apparelBuild' },
        { Header: 'Average Selling Price', accessor: 'averageSellingPrice' },
        ...attributeColumns
      ],
      classification: [
        { Header: 'Fulfillment SKU', accessor: 'mcpSku' },
        { Header: 'Country of Origin', accessor: 'countryOfOrigin' },
        ...attributeColumns,
        {
          Header: 'Country of Classification',
          accessor: 'countryOfClassification'
        },
        { Header: 'HTS Code', accessor: 'htsCode' },
        { Header: 'Is Preferential Origin?', accessor: 'preferentialOrigin' }
      ]
    }

    this.state = {
      button: 'Classify',
      tableData: data,
      tableColumns: columns,
      errorMsg: new Set()
    }
  }

  getMaterialValues = (classification) => {
    const { material, material2, material3 } = classification
    return {
      material: material && material.split(':')[0].trim(),
      'Material Percentage':
        material && (material.split(':')[1] || '100') + '%',
      material2: material2 && material2.split(':')[0].trim(),
      'Material2 Percentage':
        material2 && (material2.split(':')[1] || '100') + '%',
      material3: material3 && material3.split(':')[0].trim(),
      'Material3 Percentage':
        material3 && (material3.split(':')[1] || '100') + '%'
    }
  }

  formatData = (data) => {
    const attributeSet = new Set()
    const materialSet = new Set()
    const attributeColumns = []
    const materialColumns = []

    data.forEach((row) => {
      const materialValues = this.getMaterialValues(row)
      keys(materialValues).forEach((materialKey) => {
        if (!materialValues[materialKey]) return
        if (!materialSet.has(materialKey)) {
          materialSet.add(materialKey)
          materialColumns.push({
            Header: `${materialKey}`,
            accessor: `${materialKey}`
          })
        }
        row[materialKey] = materialValues[materialKey]
      })
      const { attributes } = row
      if (attributes && !isEmpty(attributes)) {
        keys(attributes).forEach((attribute) => {
          if (!attributeSet.has(attribute)) {
            attributeSet.add(attribute)
            attributeColumns.push({
              Header: `${attribute}`,
              accessor: `${attribute}`
            })
          }
          row[attribute] = attributes[attribute]
        })
      }
    })
    return {
      data,
      attributeColumns,
      materialColumns
    }
  }

  componentDidMount() {
    if (this.props.classificationError) {
      let message = ''
      this.state.errorMsg.forEach((err) => {
        message += err + '\n'
      })

      dispatchError(
        `${message}Please fix all the errors in the file and click "Go Back" to re-upload it.`
      )
    }
    this.buttonText()
  }

  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      if (rowInfo.original.invalid.isInvalid) {
        rowInfo.original.invalid.reasons.forEach((reason) => {
          this.state.errorMsg.add(reason)
        })
        this.setState({ errorMsg: this.state.errorMsg })
      }
      return {
        style: {
          background: rowInfo.original.invalid.isInvalid ? '#d24a35' : 'white',
          color: rowInfo.original.invalid.isInvalid ? 'white' : 'black'
        }
      }
    }
    return {}
  }

  buttonText = () => {
    if (this.props.submissionType === 'request') {
      this.setState({ button: 'Request' })
    } else if (this.props.submissionType === 'classification') {
      this.setState({ button: 'Classify' })
    }
  }

  returnToFileUpload() {
    dispatchError(null)
    dispatchMessage(null)
    dispatchFile(undefined)
    dispatchClassifications([])
  }

  async onClick() {
    try {
      dispatchWait(true)
      await classeService.sendClassifications(this.props.classifications)
      if (this.props.submissionType === 'request') {
        dispatchMessage(
          'A request to classify the products for customs was sent successfully.'
        )
      } else if (this.props.submissionType === 'classification') {
        dispatchMessage('The products have been classified successfully.')
      }
    } catch (err) {
      this.formatAndDispatchError(err)
    } finally {
      dispatchWait(false)
      dispatchClassifications([])
    }
  }

  formatAndDispatchError(err) {
    if (err.message) {
      dispatchError(err.message)
    } else {
      dispatchError(err)
    }
  }

  render() {
    return (
      <div style={{ overflowWrap: 'break-word' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="default" onClick={this.returnToFileUpload}>
            {'Go Back'}
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="primary"
            onClick={this.onClick}
            disabled={this.props.wait || this.props.classificationError}
          >
            {this.state.button}
          </Button>
        </div>
        <br />
        <br />
        <br />
        <ReactTablev6
          columns={this.state.tableColumns[this.props.submissionType]}
          data={this.state.tableData[this.props.submissionType]}
          getTrProps={this.getTrProps}
          showPagination={false}
          usePagination={false}
          defaultPageSize={this.props.classifications.length}
          sortable={false}
          resizable={true}
        />
      </div>
    )
  }
}

export default connect((state) => {
  return {
    classifications: state.classification.classifications,
    submissionType:
      state.classification.selectedImporterOfRecord.submissionType,
    wait: state.app.wait,
    classificationError:
      state.classification.classifications &&
      state.classification.classifications.find(
        (classification) => classification.invalid.isInvalid
      )
  }
})(ClassificationDisplay)
