import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import Header from '@cimpress-technology/react-platform-header'
import { Alert } from '@cimpress/react-components'
import SubmitClassificationsWrapper from './components/submitClassificationsWrapper'
import ActiveClassifications from './components/activeClassifications'
import { Route, Switch } from 'react-router-dom'

import * as auth from './lib/authentication/auth'

import { dispatchError, dispatchMessage } from './redux/notifications/actions'
import { getImportersOfRecord } from './lib/classe/classeService'
import { getIorFromCookie } from './components/iorSelect'
import IorSelect from './components/iorSelect'
import { Link } from 'react-router-dom'

export class App extends React.Component {
  constructor(props) {
    super(props)
    autobind(this)
  }

  async componentDidMount() {
    await this.initialize()
  }

  async handleLoginClick() {
    await this.initialize()
  }

  handleLogoutClick() {
    auth.logOut()
  }

  async initialize() {
    await auth.logIn()
    await getImportersOfRecord()
    await getIorFromCookie()
  }

  buildHeaderLinks() {
    let appLinks = [
      {
        id: 'iorSelect',
        content: <IorSelect />
      }
    ]

    if (this.props.selectedImporterOfRecord) {
      appLinks.push({
        id: 'submit-classifications',
        content: (
          <Link id="submit-classifications" to="/">
            Submit Classifications
          </Link>
        )
      })
      appLinks.push({
        id: 'view-classifications',
        content: (
          <Link id="request-classifications" to="/view">
            View Active Classifications
          </Link>
        )
      })
    }

    return appLinks
  }

  render() {
    return (
      <div>
        <Header
          responsive
          appTitle={'Customs Classification'}
          profile={this.props.profile}
          isLoggedIn={this.props.isAuthenticated}
          onLogInClicked={this.handleLoginClick}
          onLogOutClicked={this.handleLogoutClick}
          appLinks={this.buildHeaderLinks()}
          documentationUrl={
            'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/15145402408/Customs+Classification+User+Guide'
          }
        />
        <div className="container">
          {this.props.notifications.message && (
            <Alert
              style={{ whiteSpace: 'pre-wrap' }}
              dismissible={true}
              onDismiss={() => dispatchMessage(null)}
              type="success"
              message={this.props.notifications.message}
            />
          )}
          {this.props.notifications.error && (
            <Alert
              style={{ whiteSpace: 'pre-wrap' }}
              dismissible={false}
              onDismiss={() => dispatchError(null)}
              type="danger"
              message={this.props.notifications.error}
            />
          )}
        </div>
        <div className="flex f-center center-horizontal">
          <Switch>
            {this.props.selectedImporterOfRecord && (
              <Route
                path="/view"
                component={ActiveClassifications}
                key="viewClassifications"
              />
            )}
            <Route
              component={SubmitClassificationsWrapper}
              key="submitClassifications"
            />
          </Switch>
        </div>
      </div>
    )
  }
}

export default withRouter(
  connect((state) => {
    return {
      isAuthenticated: state.auth.isAuthenticated,
      profile: state.auth.profile,
      classifications: state.classification.classifications,
      importersOfRecord: state.classification.importersOfRecord,
      selectedImporterOfRecord: state.classification.selectedImporterOfRecord,
      notifications: state.notifications,
      isVistaprintIor: state.classification.isVistaprintIor
    }
  })(App)
)
